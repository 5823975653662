#videoview {
    position: relative;
    width: 400px;
}

#video {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1
}

#overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2
}